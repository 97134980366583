import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import $ from 'jquery'
import { AES, enc } from 'crypto-js';

import "./styles/index.css";
import AuthProvider, { logoutUser } from "./Contexts/AuthContext";
import AppProvider from "./Contexts/AppContext";
import { getCookie } from "wearslot-dev-utils";

window.token = getCookie(process.env.REACT_APP_COOKIE_NAME);
if (window.token === '' || window.token === null) {
  logoutUser()
}

window.token = AES.decrypt(window.token, process.env.REACT_APP_CRYPTO_SECRET_KEY).toString(enc.Utf8);

window.token = window?.token?.split(':')[1];
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT + '/api/v1';
axios.defaults.headers.common = {
  Authorization: "Bearer " + window.token,
  Accept: "application/json",
  'X-STORE-ID': getCookie(process.env.REACT_APP_COOKIE_STORE)
};

$.ajaxSetup({
  headers: {
    'Authorization': 'Bearer ' + window.token,
    'X-STORE-ID': getCookie(process.env.REACT_APP_COOKIE_STORE)
  }
});

const root = ReactDOM.createRoot(document.getElementById('app'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AppProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </AppProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
